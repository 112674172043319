import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "boxicons";
import "./styles.css";
import { formatMoeda } from "./functions/converts";
import { PerfectScrollbarPlugin } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/style.css";
import PrimeVue from "primevue/config";
import { initializeFacebookPixel } from "./plugins/facebook-pixel"; // Modularizado
import VueTheMask from "vue-the-mask";
import "./assets/scss/custom-bootstrap.scss";

// Componentes PrimeVue
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import Dialog from "primevue/dialog";

import "primevue/resources/themes/aura-light-teal/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

const app = createApp(App);
const pinia = createPinia();

app.config.globalProperties.$formatMoeda = formatMoeda;

app.use(pinia);
app.use(router);
app.use(PerfectScrollbarPlugin);
app.use(VueTheMask);
app.use(PrimeVue);

app.component("DataTable", DataTable);
app.component("VColumn", Column);
app.component("VToolbar", Toolbar);
app.component("VButton", Button);
app.component("VDialog", Dialog);

// Inicializar o Facebook Pixel dinamicamente
initializeFacebookPixel(app).then(() => {
  app.mount("#app");
});
