// Função para realizar o hashing de dados (SHA-256)
async function hashData(data) {
  const encoder = new TextEncoder();
  const dataBuffer = encoder.encode(data);
  const hashBuffer = await crypto.subtle.digest("SHA-256", dataBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // Cria um array de bytes
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  return hashHex; // Retorna o hash em formato hexadecimal
}

//1. Importar o Facebook Pixel:
export async function trackEvent(eventName, data = {}, trackName = "track") {
  // Verifica se o Facebook Pixel está carregado
  if (window.fbq) {
    // Realiza o hashing dos dados obrigatórios (conforme a lista fornecida)
    const hashedData = {};

    // Realiza o hashing dos dados opcionais (conforme a lista fornecida)
    if (data.em) {
      // emails
      hashedData.em = await hashData(data.em);
    }
    if (data.ph) {
      // número de telefone
      hashedData.ph = await hashData(data.ph);
    }
    if (data.fn) {
      // nome
      hashedData.fn = await hashData(data.fn);
    }
    if (data.ln) {
      // sobrenome
      hashedData.ln = await hashData(data.ln);
    }
    if (data.ge) {
      // gênero
      hashedData.ge = await hashData(data.ge);
    }
    if (data.db) {
      // data nascimento
      hashedData.db = await hashData(data.db);
    }
    if (data.ct) {
      // cidade
      hashedData.ct = await hashData(data.ct);
    }
    if (data.st) {
      // estado
      hashedData.st = await hashData(data.st);
    }
    if (data.zp) {
      // código postal
      hashedData.zp = await hashData(data.zp);
    }
    if (data.country) {
      // país
      hashedData.country = await hashData(data.country);
    }

    // Preenche o restante dos dados sem hashing
    const pixelData = {
      ...hashedData,
      external_id: data.external_id,
      client_ip_address: data.client_ip_address,
      client_user_agent: data.client_user_agent,
      fbc: data.fbc,
      fbp: data.fbp,
      subscription_id: data.subscription_id,
      fb_login_id: data.fb_login_id,
      lead_id: data.lead_id,
      anon_id: data.anon_id,
      madid: data.madid,
      page_id: data.page_id,
      page_scoped_user_id: data.page_scoped_user_id,
      ctwa_clid: data.ctwa_clid,
      ig_account_id: data.ig_account_id,
      ig_sid: data.ig_sid,
    };

    // Envia o evento com os dados rastreados
    window.fbq(trackName, eventName, pixelData);
    console.log(`Evento ${eventName} enviado:`, pixelData);
  } else {
    console.warn("Facebook Pixel não inicializado.");
  }
}