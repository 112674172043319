/* global fbq */
export default {
  install(app, options) {
    if (!options || !options.pixelId) {
      console.error('Facebook Pixel ID não fornecido!');
      return;
    }

    // Adiciona o script do Facebook Pixel dinamicamente
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    // Aguarda o carregamento do script para inicializar o Pixel
    const checkFbq = () => {
      if (typeof fbq === 'function') {
        fbq('init', options.pixelId);
        fbq('track', 'PageView');
        console.log(`Facebook Pixel iniciado com ID: ${options.pixelId}`);
        app.config.globalProperties.$fbq = fbq;
      } else {
        setTimeout(checkFbq, 100); // Tenta novamente após 100ms
      }
    };
    checkFbq();
  },
};
